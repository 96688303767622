import { useTranslation } from 'react-i18next'
import { NoPrint, RadarChart, RangeChart, TagButton, TileFormRowWithData, TileFormSpan, getAnwRadarChartData, getRadarChartLabels } from '@dataplace.ai/ui-components/atoms'
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { ReactComponent as Pin } from '@dataplace.ai/assets/lib/icons/pinIcon.svg'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import RankingModal from '@placeme/components/molecules/RankingModal/RankingModal'
import { DemoInfoSection } from '@dataplace.ai/ui-components/molecules'
import { DataType } from '../@types/IAnwPotentialModelTileData'
import { Wrapper,
  EstimationParagraph,
  Title,
  Estimation,
  EstimationLabel,
  ChartTitle,
  Section,
  SectionTitle,
  SectionMainText,
  TagButtonWrapper,
  SimilarAdressWrapper,
  SimilarAdressElement,
  AdressContentWrapper,
  AdressItem } from './AnwPotentialModelResult.styles'
import { Settings, SettingsListElement } from '../../../Mcd/Settings'
import { PotentialLabels } from '../../../BigBen/Potential/data'

export const AnwPotentialModelResult: React.FC<{
  categoryId: string,
  data: DataType,
  tileId: string,
  address: string | undefined,
  settingList: SettingsListElement[],
  labels: PotentialLabels,
  setAccepted: Dispatch<SetStateAction<boolean>> }> = ({
  data, settingList, labels, categoryId,
}) => {
  const { t } = useTranslation()
  const { value } = useSelector((state: RootState) => state.location)
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)

  const potentialModel = data?.value?.potentialModel

  const memoizedData = useMemo(() =>
    getAnwRadarChartData(potentialModel?.influence),
  [getAnwRadarChartData, potentialModel?.influence])

  const memoizedLabels = useMemo(() =>
    getRadarChartLabels(potentialModel?.influence, labels),
  [potentialModel?.influence, labels])

  return (
    <Wrapper background='light'>
      <Title>
        {t('placeme.anw.potential.title')}
      </Title>
      <Settings selectedSettings={settingList} />
      <DemoInfoSection categoryId={categoryId} />
      <Estimation>
        <EstimationParagraph>
          {t('placeme.anw.potential')}
          {' '}
          <strong>{potentialModel?.score}</strong>
        </EstimationParagraph>
        <EstimationLabel>{t('placeme.anw.potential.label')}</EstimationLabel>
      </Estimation>
      <ChartTitle>{t('placeme.anw_sales_potential_tile.radar.title')}</ChartTitle>

      <RadarChart
        data={memoizedData}
        height={450}
        labels={memoizedLabels}
      />

      <Section>
        <SectionTitle>{t('placeme.anw_potential_ranking.section_title_1')}</SectionTitle>
        <SectionMainText>{t('placeme.anw_potential_ranking.line_2')}</SectionMainText>

        <RangeChart
          label='generic.place'
          max={potentialModel?.ranking?.places.lowest}
          value={potentialModel?.ranking?.places.current}
        />
        <NoPrint>
          <TagButtonWrapper>
            <TagButton onClick={() => setModalIsOpen(true)}>
              <Pin />
              <p>{t('generic.see_ranking')}</p>
            </TagButton>
          </TagButtonWrapper>
        </NoPrint>
        <RankingModal
          currentPosition={potentialModel?.ranking?.places.current}
          isOpen={modalIsOpen}
          rankingList={potentialModel?.ranking?.list}
          setIsOpen={setModalIsOpen}
        />
      </Section>
      <Section>
        <TileFormRowWithData>
          <SectionTitle>{t('placeme.anw_similar_locations.row_with_data_1_span_1')}</SectionTitle>
        </TileFormRowWithData>
        <TileFormSpan>
          {t('placeme.anw_similar_locations.form_span_1_normal_1')}
        </TileFormSpan>
        <TileFormSpan
          isBold
          margin='20px 0 0 0'
        >
          {t('placeme.anw_similar_locations.form_span_1_normal_2')}
          {' '}
          {value?.address}
          {' '}
          {t('placeme.anw_similar_locations.form_span_1_normal_3')}
        </TileFormSpan>
        <SimilarAdressWrapper>
          {potentialModel?.similarLocations?.map(location => (
            <SimilarAdressElement key={location.id}>
              <AdressContentWrapper>
                {t('placeme.anw_similar_locations.list2')}
                {', '}
                <AdressItem>
                  {location.address}
                </AdressItem>
              </AdressContentWrapper>
            </SimilarAdressElement>
          ))}
        </SimilarAdressWrapper>
      </Section>
    </Wrapper>
  )
}
