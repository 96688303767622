import { useTranslation } from 'react-i18next'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { TitleFormSectionSubTitle } from '@dataplace.ai/ui-components/atoms'
import { getTileInfo } from 'apps/placeme/src/functions/getTileInfo'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import { TileFooter } from '@placeme/components/atoms'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { checkComparedCoinsValue } from 'apps/placeme/src/functions'
import { AnalyticsContext } from '@dataplace.ai/features'
import styled, { css } from 'styled-components'
import { placemePlans } from '@dataplace.ai/constants'
import { IRadiusTimeSelectorProps } from './@types/IRadiusTimeSelector'
import { tilesWithoutComparedLocation } from '../AddAnotherLocationModal/constants'
import Selector from './Selector'
import ClickableLabel from './results/ClickableLabel'
import { Maps } from './results/Maps'
import { useManageTimeRanges } from './hooks/useTimeRanges'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
`

const ValuesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Description = styled.span(({
  theme: {
    palette,
    typography,
  },
}) => css`
  color: ${palette.dark.normal};
  font-size: ${typography.small.pt_13_regular.fontSize};
  font-weight: ${typography.small.pt_13_regular.fontWeight};
  line-height: ${typography.small.pt_13_regular.lineHeight};
  margin-bottom: 0.5rem;
`)

const ResultsTitle = styled.span(({
  theme: {
    palette,
    typography,
  },
}) => css`
  color: ${palette.dark.normal};
  font-size: ${typography.main.pt_15_medium.fontSize};
  font-weight: ${typography.main.pt_15_medium.fontWeight};
  line-height: ${typography.main.pt_15_medium.lineHeight};
  margin-bottom: 1.5rem;
  margin-left: 0.5rem;
`)

const RadiusTimeSelector = ({
  onSubmit,
  layers,
  comparedLayers,
  tileId,
  tileName,
  tileSection,
  isExtraPaid,
  setAccepted,
  accepted,
  timeRanges,
  comparedTimeRanges,
  handleValueLabelClick,
  setMapLocation,
  setComparedMapLocation,
  contentTimeRanges,
  initalRangesState,
  timeRangesData,
  handleDeleteTile,
}: IRadiusTimeSelectorProps): JSX.Element => {
  const { t } = useTranslation()
  const { analytics } = useContext(AnalyticsContext)
  const [isComparedMapVisible, setIsComparedMapVisible] = useState(false)
  const [ranges, setRanges] = useState<Record<string, ResourceWithId[]>>(initalRangesState)

  const [timeRangesWithActive, setTimeRangesWithActive] = useManageTimeRanges(timeRanges, handleValueLabelClick, false)
  const [
    comparedTimeRangesWithActive,
    setComparedTimeRangesWithActive,
  ] = useManageTimeRanges(comparedTimeRanges, handleValueLabelClick, true)

  const hasItems = useMemo(() => Object.values(ranges).some(rangeArray => rangeArray.length > 0), [ranges])

  const {
    creditsAmount, values, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)
  const {
    value, currentSubscriptionData,
  } = useSelector((state: RootState) => state.location)

  const tileInfo = useMemo(() => getTileInfo(tileId, tileSection, values), [tileId, values, tileSection])
  const tileValue = tileInfo?.data?.value

  const onAccept = useCallback(() => {
    if (!creditsAmount || !setAccepted) return
    const allRanges = Object.values(ranges).flat()
    onSubmit(allRanges)

    setAccepted(true)
  }, [creditsAmount, ranges, tileInfo?.chosenRange, analytics, value])

  const handleSetRanges = useCallback((type: string, newRanges: ResourceWithId[]) => {
    setRanges(prevRanges => ({
      ...prevRanges,
      [type]: newRanges,
    }))
  }, [])

  const tileFooterValue = useMemo(() => (tileName === 'overlapping_ranges'
    ? '1'
    : (Object.values(ranges)
      .reduce((total, current) => total + current.length, 0) * checkComparedCoinsValue(
      comparedLocation?.generatedFromNow,
      !!comparedLocation?.location,
      !tilesWithoutComparedLocation.includes(tileId?.split('-')[0]),
    )).toString() || ''),
  [ranges, tileId, comparedLocation?.generatedFromNow, comparedLocation?.location, tilesWithoutComparedLocation])

  // shows active coverage on the map by clicking on the result from the list
  const handleValueLabelClickWithActive = useCallback((label: string, compared: boolean) => {
    if (!handleValueLabelClick) return

    const newActive : IRadiusTimeSelectorProps['timeRanges'] = []
    if (!compared) {
      timeRangesWithActive?.forEach(range => (range?.label === label
        ? newActive.push({
          ...range,
          active: true,
        })
        : newActive.push(range)))
      setTimeRangesWithActive(newActive)
    } else {
      comparedTimeRangesWithActive?.forEach(range => (range?.label === label
        ? newActive.push({
          ...range,
          active: true,
        })
        : newActive.push(range)))
      setComparedTimeRangesWithActive(newActive)
    }
  }, [timeRangesWithActive, comparedTimeRangesWithActive, handleValueLabelClick])

  return (
    <>
      {!hasItems && !tileValue
        ? (
          <>
            <TitleFormSectionSubTitle>
              {t('placeme.overlapping_ranges.time_selector.title')}
            </TitleFormSectionSubTitle>
            <Description>{t('placeme.overlapping_ranges.time_selector.description')}</Description>
          </>
        )
        : null}
      <Wrapper>
        {!accepted && !tileValue
          ? (
            <Selector
              hasItems={hasItems}
              ranges={ranges}
              setRanges={handleSetRanges}
              timeRangesData={timeRangesData}
            />
          )
          : null}
        {tileValue
          ? (
            <>
              <ValuesWrapper>
                <ResultsTitle>{t('placeme.overlapping_ranges.result.subtitle')}</ResultsTitle>
                {timeRangesWithActive?.map((resource) => (
                  <ClickableLabel
                    key={resource?.label}
                    compared={comparedTimeRanges?.find(item => item?.label === resource?.label)}
                    contentTimeRanges={contentTimeRanges}
                    handleValueLabelClick={handleValueLabelClickWithActive}
                    resource={resource}
                  />
                ))}
              </ValuesWrapper>
              <Maps
                comparedLayers={comparedLayers}
                comparedLocation={comparedLocation}
                isComparedMapVisible={isComparedMapVisible}
                layers={layers}
                setComparedMapLocation={setComparedMapLocation}
                setIsComparedMapVisible={setIsComparedMapVisible}
                setMapLocation={setMapLocation}
              />
            </>
          )
          : (
            <TileFooter
              disabled={!hasItems}
              isExtraPaid={isExtraPaid}
              isUnlimited={currentSubscriptionData?.value?.planExact
                ?.includes(placemePlans.unlimited) || currentSubscriptionData?.value?.plan === placemePlans.white}
              label={isExtraPaid ? t('generic.apply_and_buy') : t('generic.apply')}
              onAccept={onAccept}
              onCancel={handleDeleteTile}
              tile={tileName}
              value={tileFooterValue}
            />
          )}
      </Wrapper>
    </>
  )
}

export default RadiusTimeSelector
