/* eslint-disable max-lines */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Button,
  IconButton,
  CreditsCounter,
  AddIcon,
  Tag,
  Tooltip,
  DropdownButton,
  MiniLoader,
  NoPrint,
} from '@dataplace.ai/ui-components/atoms'
import { MiniMapTile } from '@dataplace.ai/ui-components/atoms/MiniMapTile/MiniMapTile'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { resetLocationState, saveComparedAnalyseId } from 'apps/placeme/src/features/ChooseLocationReport/chooseLocationSlice'
import { AuthContext } from '@dataplace.ai/features/components/AuthContext'
import { LanguageSelector, MainDropdown, StatusInfoLabel } from '@dataplace.ai/ui-components/molecules'
import { placemePlans } from '@dataplace.ai/constants'
import { useFeatureAccess, useSaveLocation } from '@dataplace.ai/functions/hooks'
import { IRange } from '@dataplace.ai/types'
import useRedirectBasedOnProjectState from 'apps/placeme/src/customHooks/useRedirectBasedOnProjectState'
import { createFlashMessage, getAxios } from '@dataplace.ai/functions/utils'
import { featureAccessIds } from 'apps/placeme/src/constants/constants'
import { IDropdownButtonProps } from '@dataplace.ai/ui-components/atoms/Buttons/DropdownButton/@types/IDropdownButton.props'
import { ReactComponent as Download } from '../../../../../../../../libs/shared/assets/src/lib/icons/dataplace/download.svg'
import { ReactComponent as TrashIcon } from '../../../../../../../../libs/shared/assets/src/lib/icons/trashIcon.svg'
import { RootState } from '../../../../../redux/store'
import { ExitFromAnalysePopup, SaveAnalyseModal } from '../../molecules'
import {
  resetAnalysisState, saveComparedLocation, toggleIsEdit,
} from '../../../slice/analysisSlice'
import { tilesPdfList } from '../../../utils/tilesPdfList'
import { AddAnotherLocationModal } from '../../molecules/AddAnotherLocationModal'
import { exitFromAnalyseAction } from '../../../functions/exitFromAnalyse'
import { SaveTemplateModal } from '../../molecules/SaveTemplateModal'
import {
  ActionsWrapper, AddAnotherLocation, CreditsCounterWrapper, LocationWrapper, StyledBlueSpan,
  StyledDropdownButton, Wrapper, DropdownContentWrapper, StyledDropdownHeader,
} from './AnalysePageHeader.styles'
import DownloadButtons from './components/DownloadButtons'

export const AnalysePageHeader = (): JSX.Element => {
  // constants
  const {
    analysisDbInfo, creditsAmount, canBeSave, values, plans, comparedLocation, comparisonState,
    synchronousTilesLoading, isEdit,
  } = useSelector((state: RootState) => state.analysis)
  const {
    value, currentSubscriptionData, analyseId, comparedAnalyseId, userType,
  } = useSelector((state: RootState) => state.location)
  const address = value?.address || ''
  const navigationRef = useRef<HTMLDivElement>(null)
  const counterRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const redirectBasedOnProjectState = useRedirectBasedOnProjectState()
  const { hasFeatureAccess } = useFeatureAccess()
  const hasKanbanAccess = hasFeatureAccess(featureAccessIds.kanban)
  const hasExcelAccess = hasFeatureAccess(featureAccessIds.excelReport)
  const { saveLocation } = useSaveLocation()

  // state
  const [isExcelLoading, setIsExcelLoading] = useState(false)
  const [compareLocationLoading, setCompareLocationLoading] = useState(false)
  const [compareLocationStatusLabel, setCompareLocationStatusLabel] = useState(false)
  const [errorCompareLocation, setErrorCompareLocation] = useState(false)
  const [errorTilesList, setErrorTilesList] = useState<string[]>()
  const [tilesToTemplateSave, setTilesToTemplateSave] = useState<{
    id: string,
    suggestedRange?: {type: IRange['type'], value: number}
  }[]>([])
  const [isLocationSaving, setIsLocationSaving] = useState(false)
  const [locationSaved, setLocationSaved] = useState(false)

  const authContext = useContext(AuthContext)

  const canSaveLocation = useMemo(() => (
    !locationSaved && hasKanbanAccess && !analysisDbInfo?.locationExists && !analysisDbInfo?.locationId
  ), [hasKanbanAccess, analysisDbInfo?.locationExists, analysisDbInfo?.locationId, locationSaved, userType])

  const isTrial = useMemo(() => (
    currentSubscriptionData?.value?.planExact === placemePlans.trial
  ), [currentSubscriptionData?.value?.planExact])

  const onSaveLocation = useCallback(async () => {
    if (!analysisDbInfo?.address || !analysisDbInfo.locationLat || !analysisDbInfo.locationLng) return
    setIsLocationSaving(true)
    await saveLocation({
      address: analysisDbInfo.address,
      lat: analysisDbInfo.locationLat,
      lng: analysisDbInfo.locationLng,
    })
    setIsLocationSaving(false)
    setLocationSaved(true)
    createFlashMessage({
      message: 'status.success.generic.ok',
    })
  }, [analysisDbInfo, getAxios])

  const dropdownContent = useMemo(() => {
    const content: IDropdownButtonProps['dropdownContent'] = [
      {
        id: 'saveTemplate',
        button: (
          <span>
            <SaveTemplateModal
              templateTiles={tilesToTemplateSave}
              trigger={<span>{t('placeme.sidebar.save_as_template')}</span>}
            />
          </span>),
      },
    ]

    if (canSaveLocation) {
      content.push({
        id: 'saveLocation',
        button: (
          <StyledDropdownButton onClick={onSaveLocation}>
            {t('placeme.analysis_navbar.dropdown.save_location')}
            {isLocationSaving ? <MiniLoader /> : null}
          </StyledDropdownButton>
        ),
        disabled: isLocationSaving,
      })
    }

    return content
  }, [canSaveLocation, isLocationSaving, tilesToTemplateSave])

  // we need ref to get current state in event listener
  const [token, _setToken] = useState('')
  const tokenRef = React.useRef(token)
  const setToken = (newTokenRef: string) => {
    tokenRef.current = newTokenRef
    _setToken(newTokenRef)
  }
  // functions
  const checkIfAnyTileSave = () => {
    let isAnyTileSave = false
    if (values) {
      values.forEach(cat => {
        cat.tiles.forEach(tile => {
          if (tile.data?.value) {
            isAnyTileSave = true
          }
          return isAnyTileSave
        })
      })
    }
    return isAnyTileSave
  }

  const checkIfCanBeInPdfOrExcel = () => {
    if (isTrial) {
      return false
    }
    let isAnyTileSave = false
    if (values) {
      values.forEach(cat => {
        cat.tiles.forEach(tile => {
          if (tile.data?.value && tilesPdfList.some(key => new RegExp(`${key}.*`, 'g').test(tile.id))) {
            isAnyTileSave = true
          }
          return isAnyTileSave
        })
      })
    }
    return isAnyTileSave
  }

  const downloadDisabled = useMemo(() => (
    synchronousTilesLoading || !currentSubscriptionData?.value || compareLocationLoading || !checkIfCanBeInPdfOrExcel()
  ), [synchronousTilesLoading, currentSubscriptionData?.value, compareLocationLoading, checkIfCanBeInPdfOrExcel])

  const handleDelete = () => {}

  const handleDeleteCompared = () => {
    window.localStorage.removeItem('comparedLocation')
    window.localStorage.removeItem('comparedAnalyseId')
    dispatch(saveComparedLocation(undefined))
    dispatch(saveComparedAnalyseId(undefined))
  }

  const getMaxNumber = () => {
    if (typeof currentSubscriptionData?.value?.credits === 'number'
      && typeof currentSubscriptionData?.value?.usage === 'number') {
      return currentSubscriptionData?.value?.usage + currentSubscriptionData?.value?.credits
    }
    return currentSubscriptionData?.value?.credits
  }

  const getTemplateTiles = useCallback(() => {
    if (values.length) {
      const tiles: { id: string, suggestedRange?: { type: IRange['type'], value: number } }[] = []
      values?.forEach(cat => cat.tiles.forEach(tile => {
        if (tile?.data?.value) {
          tiles.push({
            id: tile.id.split('-')[0],
            suggestedRange: tile?.chosenRange
              ? {
                type: tile?.chosenRange?.type,
                value: tile?.chosenRange?.value,
              }
              : undefined,
          })
        }
      }))
      setTilesToTemplateSave(tiles)
    }
  }, [values])

  // hooks
  useEffect(() => {
    // fetches user token id from authContext
    authContext.userData?.user?.getIdToken()?.then(response => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    let prevScrollPos = window.pageYOffset
    window.onscroll = function () {
      const currentScrollPos = window.pageYOffset
      if (
        currentScrollPos > 100
        && navigationRef.current
        && counterRef.current
      ) {
        if (prevScrollPos > currentScrollPos) {
          navigationRef.current.style.top = '0'
          navigationRef.current.style.opacity = '1'
          counterRef.current.style.top = '0'
          counterRef.current.style.opacity = '1'
        } else {
          navigationRef.current.style.top = '-8rem'
          navigationRef.current.style.opacity = '0'
          counterRef.current.style.top = '-8rem'
          counterRef.current.style.opacity = '0'
        }
      }
      prevScrollPos = currentScrollPos
    }
    if (prevScrollPos === 0
      && navigationRef.current
      && counterRef.current) {
      navigationRef.current.style.top = '0'
      navigationRef.current.style.opacity = '1'
      counterRef.current.style.top = '0'
      counterRef.current.style.opacity = '1'
    }
  }, [])

  useEffect(() => {
    if (counterRef.current) {
      counterRef.current.style.top = '0'
      counterRef.current.style.opacity = '1'
    }
  }, [creditsAmount])

  useEffect(() => {
    if (values) getTemplateTiles()
  }, [values])

  useEffect(() => {
    // handle loading
    if (comparisonState?.filter(item => item?.loading === true).length) {
      setCompareLocationLoading(true)
    } else {
      setCompareLocationLoading(false)
    }
    // handle error
    const errorTiles = comparisonState?.filter(item => item?.error?.length)
    if (errorTiles?.length) {
      setErrorCompareLocation(true)
      const errorTilesLabels: string[] = []

      errorTiles?.forEach(tile => values?.forEach(cat => {
        const tileLabel = cat?.tiles?.find(t =>
          t?.id === tile?.tile)?.label as string
        if (tileLabel) {
          errorTilesLabels?.push(tileLabel)
        }
      }))
      setErrorTilesList(errorTilesLabels)
    }
  }, [comparisonState])

  return (
    <>
      {compareLocationStatusLabel && (
        <StatusInfoLabel
          error={errorCompareLocation}
          errorParagraph={t('placeme.compare_location.error_para')}
          errorSpan={`${t('placeme.compare_location.error_span')}: ${errorTilesList?.map((item, index) =>
            ` ${t(item)}${index === errorTilesList?.length - 1 ? '.' : ''}`)}`}
          errorSpan2={t('placeme.compared_location.error_span2')}
          handleClose={() => {
            setErrorCompareLocation(false)
            setCompareLocationStatusLabel(false)
          }}
          loading={compareLocationLoading}
          loadingParagraph={t('placeme.compare_location.loading_para')}
          loadingSpan={t('placeme.compare_location.loading_span')}
          successParagraph={t('placeme.compare_location.success_para')}
          successSpan={t('placeme.compare_location.success_span')}
        />
      )}
      <NoPrint>
        <CreditsCounterWrapper ref={counterRef}>
          <CreditsCounter
            currentPlan={currentSubscriptionData?.value?.planExact || ''}
            maxNumber={getMaxNumber()}
            number={currentSubscriptionData?.value?.credits || 0}
            plans={plans}
          />
        </CreditsCounterWrapper>
        <Wrapper ref={navigationRef}>
          <MiniMapTile
            comparedLocation={comparedLocation?.location}
            location={value}
            mapId='currentLocation'
            shouldMapStyleBeSave
          />
          <LocationWrapper>
            <span>{t('placeme.page_header.location_analysis')}</span>
            <div>
              <span>{address}</span>
              {checkIfAnyTileSave()
                ? (
                  <ExitFromAnalysePopup
                    handleDelete={() => {
                      const isProjectSaved = analysisDbInfo?.visible
                      dispatch(resetAnalysisState())
                      dispatch(resetLocationState())
                      handleDeleteCompared()
                      redirectBasedOnProjectState(isProjectSaved)
                    }}
                    trigger={
                      !analysisDbInfo?.visible
                        ? (
                          <IconButton
                            iconAlt='edit'
                            iconSrc='assets/icons/dataplace/edit.svg'
                          />
                        )
                        : <></>
                    }
                  />
                )
                : canBeSave && (
                  <IconButton
                    iconAlt='edit'
                    iconSrc='assets/icons/dataplace/edit.svg'
                    onClick={() => {
                      const isProjectSaved = analysisDbInfo?.visible
                      dispatch(resetAnalysisState())
                      dispatch(resetLocationState())
                      handleDeleteCompared()
                      redirectBasedOnProjectState(isProjectSaved)
                    }}
                  />
                )}
            </div>
            {!comparedLocation?.location
              ? (canBeSave
                ? (
                  <AddAnotherLocationModal
                    setCompareLocationStatusLabel={setCompareLocationStatusLabel}
                    trigger={(() => {
                      const button = (
                        <AddAnotherLocation disabled={isTrial}>
                          <AddIcon />
                          <span>{t('placeme.compare_with_another_location')}</span>
                        </AddAnotherLocation>
                      )
                      return isTrial
                        ? (
                          <Tooltip
                            content={t('placeme.compare_with_another_location.disabled_for_trial')}
                            position='right top'
                          >
                            {button}
                          </Tooltip>
                        )
                        : button
                    })()}
                  />
                )
                : (() => {
                  const button = (
                    <AddAnotherLocation disabled={isTrial || !canBeSave}>
                      <AddIcon />
                      <span>{t('placeme.compare_with_another_location')}</span>
                    </AddAnotherLocation>
                  )
                  return isTrial
                    ? (
                      <Tooltip
                        content={t('placeme.compare_with_another_location.disabled_for_trial')}
                        position='right top'
                      >
                        {button}
                      </Tooltip>
                    )
                    : button
                })())
              : (!comparedAnalyseId
                ? (
                  <AddAnotherLocation>
                    <span>{t('placeme.compared_location.comparing_with')}</span>
                    <Tag
                      color='#EDF0F5'
                      margin='0 5px'
                    >
                      <span>{comparedLocation?.location?.address}</span>
                    </Tag>
                    <AddAnotherLocationModal
                      setCompareLocationStatusLabel={setCompareLocationStatusLabel}
                      trigger={(
                        <IconButton
                          iconAlt='edit'
                          iconSrc='assets/icons/dataplace/edit.svg'
                        />
                      )}
                    />
                    <TrashIcon
                      className='trash-icon'
                      onClick={handleDeleteCompared}
                    />
                  </AddAnotherLocation>
                )
                : (
                  <AddAnotherLocation>
                    <span>{t('placeme.compared_location.comparing_with')}</span>
                    <Tag
                      color='#EDF0F5'
                      margin='0 0 0 5px'
                    >
                      <span>{comparedLocation?.location?.address}</span>

                    </Tag>
                  </AddAnotherLocation>
                )
              )}

          </LocationWrapper>
          <ActionsWrapper>
            <MainDropdown
              content={(
                <DropdownContentWrapper>
                  <DownloadButtons
                    comparedReportId={comparedAnalyseId}
                    downloadDisabled={downloadDisabled}
                    hasExcelAccess={hasExcelAccess}
                    isExcelLoading={isExcelLoading}
                    isTrial={isTrial}
                    reportId={analyseId || ''}
                    setIsExcelLoading={setIsExcelLoading}
                  />
                </DropdownContentWrapper>
              )}
              header={(
                <StyledDropdownHeader $disabled={downloadDisabled}>
                  {isExcelLoading
                    ? <MiniLoader $size='1rem' />
                    : (
                      <>
                        <Download />
                        <StyledBlueSpan>{t('generic.download')}</StyledBlueSpan>
                      </>
                    )}
                </StyledDropdownHeader>
              )}
              noBorder
              transparent
            />
            <SaveAnalyseModal
              analyseId={analyseId || ''}
              handleDelete={handleDelete}
              trigger={((currentSubscriptionData?.value?.planExact
                && ['white', 'trial'].includes(currentSubscriptionData?.value?.planExact))
                && !analysisDbInfo?.visible
                ? <Button disabled={!canBeSave || !checkIfAnyTileSave()}>{t('generic.save_analyse')}</Button>
                : <></>)}
            />
            {!!(!analysisDbInfo?.visible
              && (currentSubscriptionData?.value?.planExact
              && !['white', 'trial'].includes(currentSubscriptionData?.value?.planExact))
              && tilesToTemplateSave?.length)
              && (
                <DropdownButton
                  disabled={!canBeSave || !checkIfAnyTileSave()}
                  dropdownContent={dropdownContent}
                  mainButton={(
                    <span>
                      <SaveAnalyseModal
                        analyseId={analyseId || ''}
                        handleDelete={handleDelete}
                        trigger={<>{t('generic.save_analyse')}</>}
                        wasLocationSaved={locationSaved}
                      />
                    </span>
                  )}
                />
              )}
            {isEdit && (
              <Button
                disabled={synchronousTilesLoading}
                onClick={() => {
                  const isProjectSaved = analysisDbInfo?.visible
                  exitFromAnalyseAction(dispatch, isEdit)
                  redirectBasedOnProjectState(isProjectSaved)
                }}
              >
                {t('generic.finish_editing')}

              </Button>
            )}
            {!canBeSave && (
              <Button
                disabled={synchronousTilesLoading}
                onClick={() => {
                  window.localStorage.setItem('isEdit', 'true')
                  dispatch(toggleIsEdit(true))
                }}
              >
                {t('generic.edit')}
              </Button>
            )}
            <LanguageSelector
              noArrow
              noBorder
              transparent
            />
          </ActionsWrapper>
        </Wrapper>
      </NoPrint>

    </>
  )
}
