
import React, { forwardRef, useCallback, useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import {
  LinkWithIconCheckOnMap,
  LinkWithIconDelete,
  LinkWithIconEdit,
  Range,
  Button,
  NoPrint,
} from '@dataplace.ai/ui-components/atoms'
import { ILocation, IRange } from '@dataplace.ai/types'
import { Popup } from 'reactjs-popup'
import { useTranslation } from 'react-i18next'
import { RootState } from 'apps/placeme/src/redux/store'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { mediaMax as media, useCheckForMobileDevice } from '@dataplace.ai/ui-components/themes'
import { AnalyticsContext, AuthContext } from '@dataplace.ai/features'
import { ReactComponent as Refresh } from 'libs/shared/assets/src/lib/icons/refresh.svg'
import { createNewAnalyse } from 'apps/placeme/src/features/ChooseLocationReport/chooseLocationSlice'
import { ReactComponent as DataplaceLogo } from '@dataplace.ai/assets/lib/icons/dataplace-ai-logo-simple.svg'
import useCheckIfPrinting from 'apps/placeme/src/customHooks/useCheckIfPrinting'
import { DeleteTilePopup } from '../DeleteTilePopup'
import { compareLocationCatchmentAndDataAction, deleteTileAction } from '../../../slice/analysisSlice'
import { ISectionTile } from '../../../slice/@types/ISectionTile'
import { ITile } from '../../../slice/@types/ITile'
import { ReactComponent as Menu } from '../../../../../../../../libs/shared/assets/src/lib/icons/dataplace/menu.svg'
import { getBodyPropertiesForDoublePropertiesTile } from '../AddAnotherLocationModal/functions'

interface IWrapper {
  canBeSave: boolean
  canComparedBeReload?: boolean
}

const Wrapper = styled.span<IWrapper>(({
  theme, canBeSave, canComparedBeReload,
}) => {
  const { palette } = theme
  return css`
    padding: 1rem;
    display: flex;
    background-color: ${palette.light.white};
    border-bottom: 1px solid ${palette.light.darker};
    align-items: center;

    > span:nth-child(2) {
      margin: 0 1rem;
    }

    > :last-child {
      margin-left: ${(canBeSave && !canComparedBeReload) ? 'auto' : 'none'};
    }
  `
})

const Title = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    color: ${palette.black};
    padding-right: 1rem;
    border-right: 1px solid ${palette.light.darker};
    font-size: ${typography.big.pt_18_semibold.fontSize};
    font-weight: ${typography.big.pt_18_semibold.fontWeight};
    line-height: ${typography.big.pt_18_semibold.lineHeight};

    ${media.mobile`
      font-size: 16px;
    `}
  `
})
const StyledButton = styled(Button)(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    background-color: transparent;
    color: ${palette.blue};
    font-size: ${typography.tiny.pt_12_medium.fontSize};
    font-weight: ${typography.tiny.pt_12_medium.fontWeight};
    line-height: ${typography.tiny.pt_12_medium.lineHeight};
    display: inline-flex;
    align-items: center;
    padding: 5px 0;
    cursor: pointer;
    outline: none;
    border: none;
    box-shadow: none;
    margin-left: auto;
    margin-right: 8px;
    &:hover, &:focus, &:disabled {
      background: transparent;
      color: ${palette.blue};
      border: none;
      box-shadow: none;
    }

    > svg {
      min-width: 16px;
      margin-bottom: 0;
      margin-right: 0.5rem;
    }
` })

const Actions = styled.div(
  () => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;

    > * {
      margin: 0 0.25rem;
    }
  `,
)

const MenuButton = styled.div(
  () => css`
    cursor: pointer;
    display: flex;
    align-items: center;
  `,
)

const MenuWrapper = styled.div(({ theme }) => {
  const {
    palette, corners, shadows,
  } = theme
  return css`
    padding: 0.5rem;
    background-color: ${palette.light.white};
    border-radius: ${corners.default.borderRadius};
    box-shadow: ${shadows.hover.boxShadow};

    > div > button {
      width: 100%;
    }
  `
})

const PdfLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`

export interface ITileHeaderProps {
  tile: ITile
  sectionTile: ISectionTile
  range?: IRange
  toggleChangeRangeModal?: () => void
  handleMap?: () => void
  dataLoaded: boolean
}

export const TileHeader = forwardRef(({
  range,
  toggleChangeRangeModal,
  sectionTile,
  tile,
  handleMap,
  dataLoaded,
}: ITileHeaderProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { analytics } = useContext(AnalyticsContext)
  const { canChooseRange } = tile
  const {
    canBeSave, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)
  const {
    value, analyseId, currentSubscriptionData, comparedAnalyseId,
  } = useSelector((state: RootState) => state.location)
  const authContext = useContext(AuthContext)
  const [token, setToken] = useState('')
  const [comparedButtonLoading, setComparedButtonLoading] = useState(false)
  const { isPrinting } = useCheckIfPrinting()

  const errorTilesList = window?.localStorage?.getItem('comparedFailedTiles')

  const handleDelete = () => {
    if (analyseId) {
      dispatch(deleteTileAction(token, sectionTile.id, tile.id, comparedAnalyseId || analyseId))
      analytics?.track('Tile Deleted', {
        tile: tile?.id?.split('-')[0],
        range: {
          type: range?.type,
          value: range?.value,
        },
      })
    }
  }

  const valueData = tile?.data?.value as {comparedLocation: unknown}
  const isMobileDevice = useCheckForMobileDevice()

  const tilesWithNoRange = [
    'transport',
    'scoring_model_big_ben',
    'look_alike_big_ben',
    'segmentation_big_ben',
    'satellite',
    'cities_population',
    'municipality_population',
    'customer_origin',
    'land_use_plan',
    'access_range',
    'overlapping_ranges',
    'potential_model',
    'mcd_store_chain_impact',
    'mcd_potential_model',
    'anw_potential_model',
    'potential_within_chain',
    'demo_potential_within_chain',
    'demo_scoring_prediction',
    'demo_sales_prediction_model',
    'location_type',
    'demo_store_chain_impact',
    'dol_sap_potential_within_chain',
  ]

  const checkIfPeopleTileForeign = () => {
    if (tile.id.includes('people') && value?.country !== 'PL') return t(`${tile.labelForeign}`)
    return t(`${tile.label}`)
  }

  const canBeReloadCompared = useCallback(() => {
    if (errorTilesList) return JSON.parse(errorTilesList || '')?.includes(tile?.id)
    return false
  }, [errorTilesList])

  const onReloadClick = () => {
    setComparedButtonLoading(true)
    handleReloadCompared()
  }

  const handleReloadCompared = useCallback(async () => {
    const comparedAnalyseId = window.localStorage.getItem('comparedAnalyseId')
    try {
      // post project - with compared location - create compared analyse
      if (!comparedAnalyseId) await dispatch(createNewAnalyse(analyseId, comparedLocation?.location))
    }
    finally {
      try {
        await dispatch(compareLocationCatchmentAndDataAction(
          token,
          tile?.chosenRange?.catchmentId || '',
          tile?.chosenRange as IRange,
          tile?.section || '',
          tile?.id || '',
          currentSubscriptionData?.value?.subscriptionId || '',
          comparedLocation?.location as ILocation,
          getBodyPropertiesForDoublePropertiesTile(tile.id, [tile]),
        ))
      }
      finally {
        setComparedButtonLoading(false)
      }
    }
  }, [token, currentSubscriptionData, comparedLocation])

  useEffect(() => {
    authContext.userData?.user?.getIdToken()?.then(response => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (valueData?.comparedLocation && canBeReloadCompared() && errorTilesList) {
      const comparedFailedTiles : string[] = []
      const parsedErrorTilesList : string[] = JSON.parse(errorTilesList)
      parsedErrorTilesList?.forEach(item => {
        if (item !== tile?.id) {
          comparedFailedTiles.push(item)
        }
      })
      window.localStorage.setItem('comparedFailedTiles', JSON.stringify(comparedFailedTiles))
    }
  }, [valueData, errorTilesList])

  return (
    <Wrapper
      canBeSave={canBeSave}
      canComparedBeReload={!!canBeReloadCompared() && !valueData?.comparedLocation}
      id={tile.id}
    >
      <Title>{checkIfPeopleTileForeign()}</Title>
      {!tilesWithNoRange.includes(tile.id.split('-')[0]) && range && <Range range={range} />}
      {tile.id.split('-')[0] === 'cities_population' && (
        <Range
          range={{
            id: '50000_line',
            value: 50000,
            type: 'line',
          }}
        />
      )}
      {range && !(['look_alike_big_ben', 'segmentation_big_ben'].includes(tile.id.split('-')[0])) && (
        <>
          {canChooseRange && canBeSave && (
            <NoPrint>
              <LinkWithIconEdit
                onClick={toggleChangeRangeModal}
                showLabel={!isMobileDevice}
              />
            </NoPrint>
          )}
          {/* {tile.id.split('-')[0] === 'scoring_model_big_ben' && (
            <LinkWithIconEdit
              onClick={toggleChangeRangeModal}
              showLabel={!isMobileDevice}
            />
          )} */}
          { !tilesWithNoRange.includes(tile.id.split('-')[0]) && (
            <NoPrint>
              <LinkWithIconCheckOnMap
                onClick={handleMap}
                showLabel={!isMobileDevice}
              />
            </NoPrint>
          )}
        </>
      )}
      { !!canBeReloadCompared() && !valueData?.comparedLocation && (
        <NoPrint>
          <StyledButton
            loading={comparedButtonLoading}
            loadingText={t('placeme.compared_location.recalculate')}
            onClick={onReloadClick}
            type='button'
          >
            <Refresh />
            {t('placeme.compared_location.try_again')}
          </StyledButton>
        </NoPrint>
      )}
      {canBeSave && (
        <NoPrint>
          <Actions>
            <Popup
              closeOnDocumentClick
              nested
              on='click'
              position='bottom center'
              trigger={(
                <MenuButton>
                  <Menu />
                </MenuButton>
              )}
            >
              <MenuWrapper>
                {/* <NotePopup
                  dataLoaded={dataLoaded}
                  maxLength={500}
                  sectionTile={sectionTile}
                  tile={tile}
                  trigger={<LinkWithIconAddNote focusStyle={false} />}
                />
                <Divider color={defaultTheme.palette.light.darker} /> */}
                {dataLoaded
                  ? (
                    <DeleteTilePopup
                      handleDelete={handleDelete}
                      trigger={(
                        <LinkWithIconDelete focusStyle={false} />
                      )}
                    />
                  )
                  : (
                    <LinkWithIconDelete
                      focusStyle={false}
                      onClick={handleDelete}
                    />
                  )}
              </MenuWrapper>

            </Popup>
          </Actions>
        </NoPrint>
      )}
      {isPrinting
        ? (
          <PdfLogoWrapper>
            <DataplaceLogo
              height={28}
              width={250}
            />
          </PdfLogoWrapper>
        )
        : null}
    </Wrapper>
  )
})
