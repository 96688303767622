import React, { ReactNode, useState, useEffect, useContext } from 'react'
import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { IRange, RangeType } from '@dataplace.ai/types'
import { ResourcesSelector } from '@dataplace.ai/ui-components/organisms'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { NoPrint, OutlinedButton, TagButton } from '@dataplace.ai/ui-components/atoms'
import { Popup } from 'reactjs-popup'
import { MapVisualizationModal } from '@dataplace.ai/ui-components/molecules'
import { IGeojson } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IGeojson'
import { AnalyticsContext, AuthContext } from '@dataplace.ai/features'
import { theme } from '@dataplace.ai/ui-components/themes'
import { ReactComponent as OnFoot } from '../../../../../../../../libs/shared/assets/src/lib/icons/rangeIcons/onFoot.svg'
import { ReactComponent as Car } from '../../../../../../../../libs/shared/assets/src/lib/icons/rangeIcons/car.svg'
import { ReactComponent as Range } from '../../../../../../../../libs/shared/assets/src/lib/icons/rangeIcons/range.svg'
import { ReactComponent as Wand } from '../../../../../../../../libs/shared/assets/src/lib/icons/rangeIcons/wand.svg'
import { ReactComponent as Bike } from '../../../../../../../../libs/shared/assets/src/lib/icons/rangeIcons/bike.svg'
import { ReactComponent as Pin } from '../../../../../../../../libs/shared/assets/src/lib/icons/pinIcon.svg'
import { ReactComponent as MarkOnMapIcon } from '../../../../../../../../libs/shared/assets/src/lib/icons/rangeIcons/markOnMap.svg'
import { CustomRangeModal } from '..'
import { RangeContainer } from '../../atoms'
import { RootState } from '../../../../../redux/store'
import { fetchTemporaryCatchment } from '../../../slice/analysisSlice'

const Title = styled.p(({ theme }) => {
  const {
    typography, palette,
  } = theme
  return css`
    color: ${palette.dark.normal};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
  `
})

const Description = styled.p(({ theme }) => {
  const {
    typography, palette,
  } = theme
  return css`
    margin: 2rem 0 1rem;
    color: ${palette.dark.normal};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
  `
})

const Subtitle = styled.p(({ theme }) => {
  const {
    typography, palette,
  } = theme
  return css`
    color: ${palette.dark.normal};
    font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
    font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
    line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
    text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};
    letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
    margin: 1.25rem 0 0.9375rem;

    :last-child {
      margin: 1.25rem 0 0;
    }
  `
})

const Wrapper = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.9375rem;
    background: #ffffff;
    border: 1px dashed #e1e2e3;
    box-sizing: border-box;
    border-radius: 6px;
    margin-bottom: 1rem;
  `,
)

const StyledOutlinedButton = styled(OutlinedButton)(
  () => css`
    margin: 9px 0 0;
  `,
)

const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const ChosenRangeRow = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    align-items: center;
    color: ${palette.black};

    > svg {
      width: 23px;
      height: 23px;

      path {
        fill: ${palette.blue};
      }
    }

    .draw-range {
      path {
        fill: white;
        stroke: ${palette.blue};
      }
    }
  `
})

const ChosenRangeWrapper = styled.div(({ theme }) => {
  const { typography } = theme
  return css`
    font-size: ${typography.big.pt_28_semibold.fontSize};
    font-weight: ${typography.big.pt_28_semibold.fontWeight};
    line-height: ${typography.big.pt_28_semibold.lineHeight};
    margin: 0 0 0 12px;

    span {
      margin-left: 0.25rem;
      font-size: ${typography.main.pt_15_semibold.fontSize};
      font-weight: ${typography.main.pt_15_semibold.fontWeight};
      line-height: ${typography.main.pt_15_semibold.lineHeight};
    }
  `
})

export const handleRangeUnit = (type?: RangeType, value = 0): string => {
  switch (type) {
    case 'walk':
    case 'car':
      return ' min'
    case 'bike':
      return ' min'
    case 'line':
      return value > 999 ? ' km' : ' m'
    default:
      return ''
  }
}

export const handleRangeValue = (type?: RangeType, value = 0): number => {
  if (type !== 'line') return value
  return value > 999 ? value / 1000 : value
}

export const handleRangeLabel = (type?: RangeType, value = 0): ReactNode => {
  switch (type) {
    case 'walk':
      return (
        <span>
          <Trans>placeme.range_tile.minutes_by_foot</Trans>
        </span>
      )
    case 'car':
      return (
        <span>
          <Trans>placeme.range_tile.minutes_by_car</Trans>
        </span>
      )
    case 'bike':
      return (
        <span>
          <Trans>placeme.range_tile.minutes_by_bike</Trans>
        </span>
      )
    case 'line':
      return <span>{handleRangeUnit(type, value)}</span>
    default:
      return null
  }
}

export const handleRangeIcon = (
  type?: RangeType,
  height = 23,
  width = 23,
): ReactNode => {
  switch (type) {
    case 'walk':
      return (
        <OnFoot
          height={height}
          width={width}
        />
      )
    case 'car':
      return (
        <Car
          height={height}
          width={width}
        />
      )
    case 'bike':
      return (
        <Bike
          height={height}
          width={width}
        />
      )
    case 'line':
      return (
        <Range
          height={height}
          width={width}
        />
      )
    default:
      return null
  }
}

export type RangeTileProps = {
  handleChosenRangeChange: (range?: IRange, preview?: boolean) => void
  chosenRange: IRange
  maxRanges?: {
    walk: number
    line: number
    custom: number
    car: number
    bike: number
  }
  ranges: IRange[]
  noManualSetting?: boolean
  tile: string
  noTitle?: boolean
}

export const RangeTile: React.FC<RangeTileProps> = ({
  handleChosenRangeChange,
  chosenRange,
  maxRanges,
  ranges,
  noManualSetting,
  tile,
  noTitle,
}) => {
  const { t } = useTranslation()
  const { analytics } = useContext(AnalyticsContext)
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)
  const [shouldMapBeDisplayed, setShouldMap] = useState(false)
  const { layers } = useSelector((state: RootState) => state.analysis)
  const { value } = useSelector((state: RootState) => state.location)
  const rangesWithoutCustom = ranges.filter(
    (range) => range.type !== 'custom',
  )
  const dispatch = useAppDispatch()
  const [token, setToken] = useState('')
  const authContext = useContext(AuthContext)

  const [open, setOpen] = useState(false)

  const closeModal = () => setOpen(false)

  useEffect(() => {
    authContext.userData?.user?.getIdToken()?.then((response) => {
      setToken(response)
    })
  }, [authContext])

  const resources = rangesWithoutCustom.map((range) => ({
    id: range?.id,
    content:
  <RangeContainer
    type={range?.type}
    value={range?.value}
  />,
  }))

  useEffect(() => {
    if (layers.temporaryCatchment && shouldMapBeDisplayed) {
      handleChosenRangeChange({
        ...chosenRange,
        geoJSON: {
          type: 'Polygon',
          coordinates: layers?.temporaryCatchment?.layer?.data?.coordinates,
        },
      }, true)
      setIsMapDisplayed(!isMapDisplayed)
      setShouldMap(false)
    }
  }, [layers.temporaryCatchment])

  return (
    <Wrapper>
      {!noTitle && <Title>{t('placeme.range_tile.paragraph_1')}</Title>}
      <Subtitle>{t('placeme.range_tile.paragraph_2')}</Subtitle>
      <ResourcesSelector
        maxWidth='100%'
        onChange={(resource) =>
          handleChosenRangeChange(
            ranges.find((range) => range.id === resource[0]),
          )}
        resources={resources}
        tile={tile}
        value={[chosenRange?.id]}
        wrapperType='rounded'
      />

      {!noManualSetting && (
        <Popup
          closeOnDocumentClick={false}
          modal
          onClose={closeModal}
          onOpen={() => { analytics?.track('Custom Range Button Clicked', {
            location: {
              ...value,
            },
            tile: tile.split('-')[0],
          })
          setOpen(true)
          }}
          open={open}
          overlayStyle={{
            background: theme()?.overlay?.background,
            zIndex: 1010,
          }}
          trigger={(
            <StyledOutlinedButton>
              <Wand />
              <p>{t('placeme.range_tile.set_manually')}</p>
            </StyledOutlinedButton>
          )}
        >

          <CustomRangeModal
            handleClose={closeModal}
            handleRangeSave={handleChosenRangeChange}
            maxRanges={maxRanges}
            tile={tile}
          />

        </Popup>
      )}

      <Subtitle>{t('placeme.range_tile.chosen_range')}</Subtitle>
      <BottomWrapper>
        <ChosenRangeRow>
          {chosenRange.type !== 'custom' && (
            <>
              {handleRangeIcon(chosenRange.type)}
              <ChosenRangeWrapper>
                {handleRangeValue(chosenRange.type, chosenRange.value)}
                {handleRangeLabel(chosenRange.type, chosenRange.value)}
              </ChosenRangeWrapper>
            </>
          )}
          {chosenRange.type === 'custom' && (
            <>
              <MarkOnMapIcon
                className='draw-range'
                height='23'
                width='23'
              />
              <ChosenRangeWrapper>
                <span>{t('placeme.range_tile.marked_on_map')}</span>
              </ChosenRangeWrapper>
            </>
          )}
        </ChosenRangeRow>
        <NoPrint>
          <TagButton
            onClick={() => {
              analytics?.track('Show On Map Button Clicked', {
                tile: tile?.split('-')[0],
                range: {
                  type: chosenRange?.type,
                  value: chosenRange?.value,
                },
                location: {
                  ...value,
                },
              })
              if (chosenRange.geoJSON) {
                setIsMapDisplayed(!isMapDisplayed)
              } else if (value) {
                dispatch(
                  fetchTemporaryCatchment(token, {
                    length: chosenRange.value,
                    lat: value.lat,
                    lng: value.lng,
                    mode: chosenRange.type,
                  }),
                )
                setShouldMap(true)
              }
            }}
          >
            <Pin />
            <p>{t('generic.see_on_map')}</p>
          </TagButton>
        </NoPrint>

        {isMapDisplayed && (
          <MapVisualizationModal
            isDisplayed={isMapDisplayed}
            layers={
              chosenRange?.geoJSON?.coordinates || layers.temporaryCatchment?.layer?.data?.coordinates
                ? [
                  {
                    id: chosenRange?.catchmentId || 'dd',
                    layer: {
                      data: {
                        coordinates: chosenRange?.geoJSON
                          ?.coordinates as IGeojson['data']['coordinates'] || layers.temporaryCatchment?.layer?.data?.coordinates,
                        type: 'Polygon',
                        properties: {},
                      },
                      options: {
                        type: 'geojson',
                        id: 'some_overlay_id',
                        style: {
                          color: '#0000a2',
                          fillColor: '#0000a2',
                          weight: 0,
                          fillOpacity: 0.3,
                        },
                      },
                    },
                  },
                ]
                : undefined
            }
            location={value}
            mapId={`range-map-${chosenRange?.catchmentId}`}
            setIsDisplay={setIsMapDisplayed}
          />
        )}
      </BottomWrapper>
      <Description>{t('placeme.range_tile.range_description')}</Description>
    </Wrapper>
  )
}
